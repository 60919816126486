import 'bulma/css/bulma.css'
import {graphql, useStaticQuery} from 'gatsby'
import React, {Fragment} from 'react'
import {Helmet} from 'react-helmet'
import {ThemeProvider} from 'styled-components'
import {themes} from '../constants/colors'
import '../style/index.css'

export const Layout: React.FC = ({children}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          {name: 'description', content: 'Harold Waterkeyn Website'},
          {
            name: 'keywords',
            content: 'react, engineer, resume, curriculum vitae',
          },
        ]}
        defer={false}
      >
        <html lang="en" />
      </Helmet>

      <ThemeProvider theme={themes.light}>
        <Fragment>{children}</Fragment>
      </ThemeProvider>
    </>
  )
}
