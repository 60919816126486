export const colors = {
  background: 'rgba(254,173,13,.7)',
  backgroundEnd: '#ff9c00',

  text: 'hsl(0, 0%, 17%)',
  textSecondary: 'hsl(0, 0%, 40%)',
  textTernary: 'hsl(0, 0%, 45%)',
}

export const themes = {
  light: {
    background: 'rgba(254,173,13,.7)',
    backgroundEnd: '#ff9c00',
  },

  dark: {
    background: '#444',
    backgroundEnd: '#222',
  },
}
